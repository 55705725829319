import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/noahgilmore.com/noahgilmore.com/src/components/BlogPageLayout.jsx";
import Tweet from '../../components/Tweet';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Writing testable Swift code is an ongoing process, so much so that you could `}<a parentName="p" {...{
        "href": "https://twitter.com/noahsark769/status/1257347091233374212"
      }}>{`write a book about it`}</a>{`, but yesterday I was reminded of a really great insight I read long ago in `}<a parentName="p" {...{
        "href": "https://www.oreilly.com/library/view/working-effectively-with/0131177052/"
      }}>{`Working Effectively with Legacy Code`}</a>{` about testing private methods:`}</p>
    <Tweet tweetId="1265784850579025921" mdxType="Tweet" />
    <p>{`I came across a really great example yesterday where a coworker and I were able to refactor a private method to make it testable. In this post I'd like to go over that example with the hope that you can apply a similar pattern to make testing your own code easier.`}</p>
    <blockquote>
      <p parentName="blockquote">{`tl;dr: If there's a private method you want to test, avoid making it public just to test it - instead, extract it out to a public method on another object.`}</p>
    </blockquote>
    <h1 {...{
      "id": "testing-private-methods"
    }}>{`Testing Private Methods`}</h1>
    <p>{`When you're writing code, it's common to encounter the following scenario: you're writing a class which is becoming increasingly complex, and you realize there's a private method which is complex enough that you don't trust yourself to make sure it works correctly without a test.`}</p>
    <p>{`Here's a concrete example (code is paraphrased from real production code I reviewed at work). Let's say we're building an app which manages reports that the user can fill out. There are different report types, and a user can be assigned to a report. We're viewing some subset of the reports based on the types and users we've selected, and we want to show stats on our page about how many reports we're looking at of each type. In order to configure our view, we have a Store class which provides a ViewModel.`}</p>
    <p>{`The code might look something like this:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`final`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`class`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`ReportsStore`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`private`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`var`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` _currentlySelectedUserIds: [`}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Int`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`]`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`private`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`var`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` _currentUser: User`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`private`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`var`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` _seletedTypes: [ReportType]`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`// ... initializer and setup omitted ...`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`/// - Returns: The number of draft reports of each of the currently`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`// selected report types relevant to either the current user id or the`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`// users we're currently filtering by, keyed by report type`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`private`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`_getDraftCounts`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`() -> [`}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Int`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Int`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`] {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` userIds = `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`_currentlySelectedUserIds`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` + [`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`_currentUser`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`id`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        ]`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`var`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` draftCounts = [[`}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Int`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Int`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`]]()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        userIds.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`forEach`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` { userId `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`in`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` filter = `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`ReportsFilter`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`status`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: .`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`draft`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`userId`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: userId,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`types`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`_selectedTypes`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            )`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`do`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` draftCount = `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`try`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`dataRepository`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                    .`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`countReportsByType`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(filter)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                draftCounts.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`append`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(draftCount)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            } `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`catch`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                Logger.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`shared`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`error`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                    `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"Error: unable to retrive draft counts"`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                )`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` totalCounts = draftCounts`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            .`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`flatMap`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` { `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`$0`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            .`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`reduce`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`([`}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Int`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Int`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`]()) { dict, tuple `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`in`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`var`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` nextDict = dict`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                nextDict[tuple.0] = tuple.0`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` nextDict`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` totalCounts`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`/// - Returns: the view model which our view will use`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`/// to configure itself`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`public`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`viewModel`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`() -> ReportsViewModel {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`// ... do more work to figure out the other properties`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`// of the view model ...`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`ReportsViewModel`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`// ...`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`draftCounts`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`_getDraftCounts`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`// ...`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        )`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`The method takes the current state of the store (`}<inlineCode parentName="p">{`_currentlySelectedUserIds`}</inlineCode>{`, `}<inlineCode parentName="p">{`_currentUser`}</inlineCode>{`, `}<inlineCode parentName="p">{`_selectedTypes`}</inlineCode>{`), creates a filter object and passes that to a `}<inlineCode parentName="p">{`DataRepository`}</inlineCode>{` object to actually get the report models. It then processes the report models to create the stats.`}</p>
    <p>{`For me, the `}<inlineCode parentName="p">{`_getDraftCounts`}</inlineCode>{` sets off the testing alarm bell. We want to make sure we get the right reports, and we want to make sure the post-processing to create the stats works - it's complicated enough that I want to write tests to make sure I didn't make a mistake, but figuring out `}<em parentName="p">{`what the best way is`}</em>{` to get a test around this isn't that easy.`}</p>
    <h1 {...{
      "id": "private-vs-public"
    }}>{`Private vs Public`}</h1>
    <p>{`It may be tempting to just expose the `}<inlineCode parentName="p">{`_getDraftCounts`}</inlineCode>{` method as public in order to test it. It's less code, after all: no changes required to the actual method, and the test is small:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`testGetDraftCounts`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`() {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` store = `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`ReportsStore`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`// ... set up the data repository with the right data ...`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`// ... set up the store with the right selected types, etc ...`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` draftCounts = store.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`_getDraftCounts`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`XCTAssertEqual`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(draftCounts, `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`/* ... */`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`But there are a couple of issues with this:`}</p>
    <ol>
      <li parentName="ol">{`The setup required to get the `}<inlineCode parentName="li">{`ReportsStore`}</inlineCode>{` into a state where you can test `}<inlineCode parentName="li">{`_getDraftCounts`}</inlineCode>{` is non-trivial. It might be as easy as setting some variables, or it might be as hard as calling a complicated chain of methods. Or worse, you might actually have to expose `}<em parentName="li">{`other`}</em>{` private methods as public in order to set the class up for testing!`}</li>
      <li parentName="ol">{`It's testing the implementation of `}<inlineCode parentName="li">{`ReportsStore`}</inlineCode>{`, not the interface. `}<inlineCode parentName="li">{`_getDraftCounts`}</inlineCode>{` is private because it's an implementation detail - no outside consumer (other than the test) is ever going to want to call `}<inlineCode parentName="li">{`_getDraftCounts`}</inlineCode>{` directly. That means that if we want to refactor how the store calculates stats in the future (imagine if we introduced a different model and wanted to include that new model in the stats), the test would break. Having these brittle (easy to break) tests around increases the overhead of maintaining the whole system.`}</li>
    </ol>
    <h1 {...{
      "id": "single-responsibility"
    }}>{`Single Responsibility`}</h1>
    <p>{`To understand how to refactor this code so it's easier to test, it's useful to note that the method has one main dependency: the data repository. The method's primary responsibility is to figure out how to query the data repository.`}</p>
    <p><em parentName="p">{`So, why not make the data repository do the work?`}</em></p>
    <p>{`If we extract the work do the data repository, the code gets less confusing:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`extension`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`DataRepository`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`public`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`getDraftCounts`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`userIds`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: [`}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Int`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`],`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`types`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: [ReportType]`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    ) -> [`}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Int`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Int`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`] {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`var`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` draftCounts = [[`}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Int`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Int`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`]]()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        userIds.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`forEach`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` { userId `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`in`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` filter = `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`ReportsFilter`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`status`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: .`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`draft`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`userId`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: userId,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`types`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: types`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            )`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`do`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` draftCount = `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`try`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`countReportsByType`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(filter)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                draftCounts.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`append`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(draftCount)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            } `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`catch`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                Logger.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`shared`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`error`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                    `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"Error: unable to retrive draft counts"`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                )`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` totalCounts = draftCounts`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            .`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`flatMap`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` { `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`$0`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            .`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`reduce`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`([`}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Int`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Int`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`]()) { dict, tuple `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`in`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`var`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` nextDict = dict`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                nextDict[tuple.0] = tuple.0`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` nextDict`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` totalCounts`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`final`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`class`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`ReportsStore`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`// ...`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`private`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`_getDraftCounts`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`() -> [`}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Int`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Int`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`] {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`dataRepository`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`getDraftCounts`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`userIds`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`_currentlySelectedUserIds`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` + [`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`_currentUser`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`id`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`],`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`types`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`_seletedTypes`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        )`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`Now that we've extracted the method, we can completely eliminate the need to set up the Store class, and in fact our test won't care about the store at all!`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`testGetDraftCounts`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`() {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` repository = `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`DataRepository`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`// ... set up the data repository with the right data ...`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` draftCounts = repository.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`getDraftCounts`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`XCTAssertEqual`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(draftCounts, `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`/* ... */`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`Also:`}</p>
    <ol>
      <li parentName="ol">{`The implementation of `}<inlineCode parentName="li">{`_getDraftCounts`}</inlineCode>{` in the `}<inlineCode parentName="li">{`ReportsStore`}</inlineCode>{` is free to change or rename itself without this test breaking.`}</li>
      <li parentName="ol">{`If another store comes along in the future that wants to use the same counting logic in a different way, only the DataReopsitory needs to be updated, not the `}<inlineCode parentName="li">{`ReportsStore`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`If we want to test that `}<inlineCode parentName="li">{`ReportsStore`}</inlineCode>{` calls `}<inlineCode parentName="li">{`getDraftCounts`}</inlineCode>{` with the correct parameters, we can do that in a separate test that injects the `}<inlineCode parentName="li">{`DataRepository`}</inlineCode>{` as an interface.`}</li>
      <li parentName="ol">{`The `}<inlineCode parentName="li">{`_getDraftCounts`}</inlineCode>{` function isn't exposed on the store, so some other class can't violate the store's interface by calling it directly.`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`ReportsStore`}</inlineCode>{` doesn't have to care about constructing a `}<inlineCode parentName="li">{`ReportsFilter`}</inlineCode>{`. If `}<inlineCode parentName="li">{`DataRepository`}</inlineCode>{` wants to stop using a filter object in the future, we'll only have to change the `}<inlineCode parentName="li">{`DataRepository`}</inlineCode>{` method, not the `}<inlineCode parentName="li">{`ReportsStore`}</inlineCode>{` method.`}</li>
    </ol>
    <h1 {...{
      "id": "how-do-i-know-when-to-do-this"
    }}>{`How do I know when to do this?`}</h1>
    <p>{`This is only one refactoring strategy, and it doesn't apply to all cases. However, I usually find that you can apply this if the following are true:`}</p>
    <ol>
      <li parentName="ol">{`You have a complicated private method on a large class (like `}<inlineCode parentName="li">{`_getDraftCounts`}</inlineCode>{`)`}</li>
      <li parentName="ol">{`The private method has one "main" dependency (like `}<inlineCode parentName="li">{`dataRepository.countReportsByType`}</inlineCode>{`)`}</li>
      <li parentName="ol">{`The private method doesn't reference `}<inlineCode parentName="li">{`self`}</inlineCode>{` very much.`}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote">{`Note: If the private method doesn't reference `}<inlineCode parentName="p">{`self`}</inlineCode>{` as all, then it probably should be a util function, not a method!`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Also: If the private method doesn't just have one main dependency, then you can still apply this strategy, but you'll often end up injecting a protocol that includes all of the calls to dependencies that you need, and extracting the method to `}<em parentName="p">{`an extension of that protocol`}</em>{` instead of an extension of the main dependency.`}</p>
    </blockquote>
    <h1 {...{
      "id": "faq"
    }}>{`FAQ`}</h1>
    <h3 {...{
      "id": "this-seems-like-a-lot-of-work-id-rather-just-expose-the-private-method-for-testing"
    }}>{`This seems like a lot of work, I'd rather just expose the private method for testing.`}</h3>
    <p>{`It's true that it's more work, but it's not `}<em parentName="p">{`that much`}</em>{` more work, and it pays off heavily in the long run. Instead of having the `}<inlineCode parentName="p">{`ReportsStore`}</inlineCode>{` with a complicated method which is entangled with the data repository, you now have two separate objects which are independently maintainable and testable, which keeps the code robust as you keep adding to it.`}</p>
    <h3 {...{
      "id": "isnt-this-exposing-too-many-public-methods"
    }}>{`Isn't this exposing too many public methods?`}</h3>
    <p>{`When I tweeted about this, a few people raised concerns to the effect of "sure this works, but you're still creating an unnecessary public method". It's true that you're creating more public surface area of your API, but it's for good reason. `}</p>
    <p>{`There are caveats though: in a small app with only one Swift module, everything `}<inlineCode parentName="p">{`internal`}</inlineCode>{` is effectively `}<inlineCode parentName="p">{`public`}</inlineCode>{`, but you control all the consumers of public methods, so it's okay to expose more public APIs since you have a handle on what will be calling them.`}</p>
    <p>{`This isn't the case if you're developing a library, or even if you're developing a common part of a large app with a large team (in which case, you're `}<em parentName="p">{`effectively`}</em>{` developing a library). You can still use this refactoring technique, but you'll want to extract the code to an internal method rather than a public one. If you're working in a very large codebase with only one Swift module, you're a bit out of luck as far as compiler safety goes, but you can use other techniques to deter consumers from calling the method directly, like prefixing the method with an underscore, docblocks warning against usage, or linting.`}</p>
    <h3 {...{
      "id": "this-creates-too-many-wrapper-classes-for-no-reason"
    }}>{`This creates too many wrapper classes for no reason!`}</h3>
    <p>{`If you follow this strategy a lot, you'll definitely create more classes than you had before. However, in my experience I've found two things to be true:`}</p>
    <ol>
      <li parentName="ol">{`The class which you want to extract the method to usually already exists`}</li>
      <li parentName="ol">{`Usually it makes sense for the method to live on that class anyway`}</li>
      <li parentName="ol">{`When you do have to create a new class, it feels more like a general utility, not something you need to keep around and frown at just because the tests are forcing you to. You can also declare the class in the same file as the method you extracted it from, or even as a nested class!`}</li>
    </ol>
    <h3 {...{
      "id": "what-about-visiblefortesting"
    }}>{`What about VisibleForTesting?`}</h3>
    <p>{`Android has a JVM annotation called `}<a parentName="p" {...{
        "href": "https://developer.android.com/reference/androidx/annotation/VisibleForTesting"
      }}>{`VisibleForTesting`}</a>{` which relaxes visibilty constraints so that you can call private methods in tests. This can be useful for when you're developing a library (like mentioned above) and you don't want to expose a method publicly, but if you think to yourself "I'll just put VisibleForTesting on this method so that I can test it directly", that's very similar to making the method public for testing and subject to many of the same issues.`}</p>
    <h3 {...{
      "id": "what-about-performance"
    }}>{`What about performance?`}</h3>
    <p>{`At least one person who replied to the tweet was concerned about whether using this strategy results in performance overhead because it's slower to have to allocate memory for more objects. I have two thoughts about this:`}</p>
    <ol>
      <li parentName="ol">{`Usually you'll find this strategy results in a new method in an existing class, not a new class. In all but the most performance-critical code, an extra function call and stack frame won't impact anything.`}</li>
      <li parentName="ol">{`Be careful not to prematurely optimize for performance. If you notice lagging or performance issues, take the time to profile your code and figure out what the main drivers of the slowness are - almost always, having an additional object allocation won't be the issue, but if it is, you can deal with that case when it comes up.`}</li>
    </ol>
    <h1 {...{
      "id": "conclusion"
    }}>{`Conclusion`}</h1>
    <p>{`Hopefully this example was a bit more fully fleshed out and gave a better rationale for using this refactoring strategy than can fit in 280 characters. If you're interested in more content about Swift refactoring and testing, you can `}<a parentName="p" {...{
        "href": "https://twitter.com/noahsark769"
      }}>{`follow me on Twitter`}</a>{`.`}</p>

    <style {...{
      "className": "vscode-highlight-styles"
    }}>{`
  
  .dark-default-dark {
background-color: #1E1E1E;
color: #D4D4D4;
}

.dark-default-dark .mtk1 { color: #D4D4D4; }
.dark-default-dark .mtk2 { color: #1E1E1E; }
.dark-default-dark .mtk3 { color: #6A9955; }
.dark-default-dark .mtk4 { color: #569CD6; }
.dark-default-dark .mtk5 { color: #D16969; }
.dark-default-dark .mtk6 { color: #D7BA7D; }
.dark-default-dark .mtk7 { color: #B5CEA8; }
.dark-default-dark .mtk8 { color: #CE9178; }
.dark-default-dark .mtk9 { color: #646695; }
.dark-default-dark .mtk10 { color: #4EC9B0; }
.dark-default-dark .mtk11 { color: #DCDCAA; }
.dark-default-dark .mtk12 { color: #9CDCFE; }
.dark-default-dark .mtk13 { color: #000080; }
.dark-default-dark .mtk14 { color: #F44747; }
.dark-default-dark .mtk15 { color: #C586C0; }
.dark-default-dark .mtk16 { color: #6796E6; }
.dark-default-dark .mtk17 { color: #808080; }
.dark-default-dark .mtki { font-style: italic; }
.dark-default-dark .mtkb { font-weight: bold; }
.dark-default-dark .mtku { text-decoration: underline; text-underline-position: under; }
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      